import FAQ from '../components/_faq';

function initFAQ() {
    const sections = document.querySelectorAll('[data-faq]');
    if (!sections) return;

    sections.forEach(item => new FAQ(item));
}

if (
    document.readyState === 'complete' ||
    (document.readyState !== 'loading' && !document.documentElement.doScroll)
) {
    initFAQ();
} else {
    document.addEventListener('DOMContentLoaded', initFAQ);
}
